import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { backTo: String }

  close() {
    if(this.backToValue)
      fetch(this.backToValue, {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        }
      })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    else {
      document.querySelector("[id='modal']").innerHTML = '';
      document.querySelector("[id='modal']").src = '';
    }
  }

  buttonClose() {
    document.querySelector("[id='survey-modal']").innerHTML = '';
    document.querySelector("[id='survey-modal']").src = '';
  }


  bgClose(event) {
    if(event.target == document.querySelector("[id='modal-bg']"))
      this.close();
  }

  escClose(event) {
    if(event.key === 'Escape')
      this.close();
  }

}
