import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'button', 'msg' ]

  copy() {
    navigator.clipboard.writeText(this.element.dataset.clipboardCopyValue)
    this.buttonTarget.style.display = 'none';
    this.msgTarget.style.display = 'block';
  }

}