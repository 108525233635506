import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  insertHiddenField() {
    var input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', '_soft');
    input.setAttribute('value', 'true');
    this.element.appendChild(input);
  }

  softSubmit() {
    this.insertHiddenField();
    this.element.requestSubmit();
  }

  softSubmitOnEnter(event) {
    if(event.keyCode == 13) this.softSubmit();
  }

}
