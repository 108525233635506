import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ 'hidden' ]

  upload(file, url, token, name) {
    const upload = new DirectUpload(file, url, token, name);
    upload.create((error, blob) => {
      if (error) {
        alert('Upload failed');
        console.log(error);
      } else {
        this.hiddenTarget.value = blob.signed_id
        this.softSubmit()
      }
    });
  }

  pick(event) {
    this.upload(
      event.target.files[0],
      event.target.dataset['directUploadUrl'],
      event.target.dataset['directUploadToken'],
      event.target.dataset['directUploadAttachmentName']
    )
    event.target.value = null;
  }

  softSubmit() {
    this.application.getControllerForElementAndIdentifier(this.hiddenTarget.form, 'soft-submit').softSubmit()
  }

}
