import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener('keypress', this.filter);
  }

  filter(event) {
    if(event.keyCode == 13 && event.target.tagName == 'INPUT') event.preventDefault();
  }

}