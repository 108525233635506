import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

export default class extends Controller {
  static targets = [ 'active', 'inactive', 'checkbox' ]

  connect() {
    this.update()
    this.tooltip()
  }

  tooltip() {
    if (!this.checkboxTarget.disabled) {
      return
    }
    // Certain fields is only editable by admin, eg. ADMIN_ON_TAG
    const content = "To edit this, please contact nexus@lh-innovationhub.com"
    tippy(this.element, {
      content,
      placement: 'top', // Customize as needed
    })
  }

  update() {
    this.activeTarget.classList.toggle('hidden', !this.checkboxTarget.checked);
    this.inactiveTarget.classList.toggle('hidden', this.checkboxTarget.checked);
  }
}
