import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="follow-external-link"
export default class extends Controller {
  confirm(event) {
    event.preventDefault();
    event.stopPropagation();
    if (confirm(I18n.follow_external_link)) {
      window.open(event.target.href || event.params.url, '_blank');
    }
  }
}
