import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"
import chartAnnotationsPlugin from 'chartjs-plugin-annotation'

window.Chart = Chart;

export default class extends Controller {
  static targets = [
    "sessionDurationGraphCanvas"
  ]

  static values = {
    durations: Array
  }

  initialize() {
    Chart.register(chartAnnotationsPlugin);
  }

  connect() {
    const data = this.durationsValue;
    new Chart(this.sessionDurationGraphCanvasTarget, {
      type: 'bar',
      options: {
        animation: false,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          x: {
            title: {
              text: 'Duration'
            },
            ticks: {
              color: '#fff'
            },
            grid: { drawTicks: false, borderColor: '#fff' }
          },
          y: {
            ticks: { color: '#fff' },
            grid: { drawTicks: false, borderColor: '#fff' }
          }
        }
      },
      data: {
        labels: data.map(row => row.duration),
        datasets: [
          {
            label: 'Sessions',
            data: data.map(row => row.count),
            borderColor: '#FFFFFF',
            backgroundColor: '#00FF80',
            showLine: false
          }
        ]
      }
    });
  }
}

