import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'button', 'checkbox' ]

  connect() {
    this.toggleButton()
  }

  toggleButton() {
    this.buttonTarget.toggleAttribute('disabled', !this.checkboxTarget.checked);
  }

}