import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'value', 'select' ]

  connect() {
    this.update()
  }

  update() {
    this.valueTarget.textContent = this.selectTarget.options[this.selectTarget.selectedIndex].text;
  }

  submit() {
    this.selectTarget.form.requestSubmit();
  }

}
