import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ['select']

  connect() {
    let selected
    if (this.selectTarget.dataset.selected) {
      if (this.selectTarget.dataset.selected.startsWith('[') && this.selectTarget.dataset.selected.endsWith(']')) {
        selected = JSON.parse(this.selectTarget.dataset.selected).filter(selectedItem => !!selectedItem);
      } else {
        selected = [this.selectTarget.dataset.selected];
      }
    }
    this.tomSelectInstance = new TomSelect(this.selectTarget, {
      plugins: ['remove_button'],
      maxItems: null,
      items: selected,
      onChange: () => this.handleSelectChange(),
      render:{
        option: function(data, escape) {
            return '<div class="text-base">' + escape(data.text) + '</div>';
        },
        item: function(data, escape) {
            return '<div class="text-base">' + escape(data.text) + '</div>';
        },
        dropdown:function(){
            return '<div class="text-base"></div>';
        },
    },
    });
  }

  handleSelectChange() {
    this.selectTarget.form.requestSubmit();
  }

  disconnect() {
    this.tomSelectInstance.destroy();
  }
}
