import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    onboardingPath: String
  }

  connect() {
    if(this.onboardingPathValue) {
      // Turbo.visit(this.onboardingPathValue)
      fetch(this.onboardingPathValue, {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        }
      })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
    }
  }

  start() {
    document.getElementById("onboardingSurveyInstructions").remove();
  }
}
