import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'field' ]

  clear(event) {
    event.preventDefault();
    this.fieldTarget.value = null;
  }

}