import { Controller } from "@hotwired/stimulus"
import * as microsoftTeams from '@microsoft/teams-js';

export default class extends Controller {
  connect() {
    console.log('Initializing MS Team controller ...')

    const environment = this.getEnvironment()
    let domain
    switch (environment) {
      case 'production':
        domain = "https://nexus.lh-innovationhub.de"
        break
      case 'staging':
        domain = "https://nexus-staging.lh-innovationhub.de"
        break
      default:
        domain = "http://localhost:3000"
    }

    // https://learn.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/tab-sso-code
    microsoftTeams.app.initialize().then(async function () {
      console.log('Initializing MS Team app ...')
      const context = await microsoftTeams.app.getContext()
      const tenantId = context.user.tenant.id
      document.getElementById('nexus-button').addEventListener('click', async function() {
        microsoftTeams.authentication.getAuthToken().then((idToken) => {
          const searchParams = new URLSearchParams({
            ad_tenant_id: tenantId,
            id_token: idToken,
            app_type: "ms_teams",
          })

          const callbackUrl = `${domain}/session/callback`
          window.open(`${callbackUrl}?${searchParams.toString()}`, '_blank');
        }).catch((error) => {
          console.log('error', error)
        })
      });
    })
  }

  getEnvironment() {
    return document.head.querySelector("meta[name=rails_env]").content
  }
}
