import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.hasChanged = false
    window.addEventListener('beforeunload', this.confirm);
    window.addEventListener('turbo:before-visit', this.confirm);
  }

  disconnect() {
    this.hasChanged = false
    window.removeEventListener('beforeunload', this.confirm);
    window.removeEventListener('turbo:before-visit', this.confirm);
  }

  markChanged = () => {
    this.hasChanged = true
  }

  confirm = (event) => {
    if (!this.hasChanged) return;

    if (event.type == "turbo:before-visit") {
      if (!window.confirm(I18n.unload_page_confirm)) event.preventDefault()
    } else {
      event.returnValue = I18n.unload_page_confirm;
      return event.returnValue;
    }
  }

}
