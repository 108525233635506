import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    setTimeout(() => this.fade(), 2500);
  }

  fade() {
    this.element.classList.toggle('hidden')
  }

}
